import React from 'react'
import { Button, Header, Icon } from 'semantic-ui-react'
import { NumberParam, withQueryParams } from 'use-query-params'
import { openInteractiveNotification, openNotificationWithIcon } from '../../../shared/helpers/message.helpers'
import rezgloImg from '../../../assets/web-Bkgd.jpg'
import rezglologo from '../../../assets/rezglologo.png'
import { DriverStatus, GeoInfoType, GoogleType, LocationType, SendDataEvent } from '../../../shared/helpers/interface.helpers'
import { isEqual } from 'lodash'
import { getcompanyLogo } from '../../../shared/store/actions/authActions'
import {
    addMarker,
    calculateAndDisplayRoute,
    getInfoLocation,
    getLocationName,
    optionsPosition,
    setIconMarker,
    tryInfoPopup,
    getDurationTrip,
    setMapCenter,
    checkTravelStatus
} from '../../../shared/helpers/map.helpers';
import { IDriverProvider, DriverContext } from "../../../shared/context/DriverProvider";
import Spining from "../../../shared/components/Spining";
import { LOAD } from "../container/SPContainer";
import { Col, notification, Row } from 'antd';
import "../styles/SharedPosition.css";
import moment from 'moment';
import PassengerInfo from "./PassengerInfo";
import CancellationInfo from './CancellationInfo';
import TimerTrip from './TimerTrip';
import TripDetails from './TripDetails';
import { SendDataEventEnum, StatusTripEnum, TravelStatusTypeEnum, TravelTimeType } from '../../../shared/enums/driver.enums';
import bookingModel from "../../../shared/fetch/booking.model";
import restModel from "../../../shared/fetch/rest.model";
import Chronometer from "../../../shared/components/Chronometer";
import { timeDiffTrip } from "../../../shared/helpers/date.helpers";
import { RezgloIconSvg } from "../../../shared/helpers/icon.hepers";
import DriverNotes from "./DriverNotes";
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import FloatingButton from "../../../shared/components/FloatingButton/FloatingButton";
import DriverInfo from '../../../shared/components/DriverInfo/DriverInfo';
import EndTripConfirmation from "./EndTrip/EndTripConfirmation";
import EndTripInvoice from "./EndTrip/EndTripInvoice";
import EndTripPaymentSelection from "./EndTrip/EndTripPaymentSelection";
import { TripStop } from './TripStop';
import profileNoimage from "../../../assets/profileNoimage.png";
import { isNullOrUndefined } from '../../../shared/helpers/string.helpers';
import { showGraphqlErrorNotifications } from "../../../shared/helpers/graphql.helpers";
import apiModel from '../../../shared/fetch/api.model';

declare let google: GoogleType

interface IPassengerKeys {
    isPaymentCard: boolean;
    isPaymentWallet: boolean;   
    defaultCreditCard: object;   
}
interface IState {
    location?: LocationType;
    pickupLocation?: LocationType;
    dropoffLocationName: string;
    locationName?: string;
    lastTwoHeadings: number[];
    estimatedDurationPU?: string;
    estimatedDurationDO?: string;
    countryName?: string;
    tripStatus?: StatusTripEnum;
    isShared?: boolean;
    driverNotesVisible?: boolean;
    map?: any;
    marker?: any;
    loading?: boolean;
    greetingSign?: boolean;
    openModalCanceled?: boolean;
    dropOffTime?: any;
    booking: any;
    companyLogo: any;
    companyName: string;
    durationTrip: string;
    legs: any;
    directionsRenderer: any;
    borderDirectionsRenderer: any;
    accuracyCircle: any;
    cachedRouteDistance: number;
    PassengerGet: IPassengerKeys;
    tripBelongsToDriver: boolean;
    isTripCancelled?: boolean;
    isMapFullScreen: boolean
    isLocationBeingShared: boolean;
    distanceCovered: number;
}

interface IProps {
  query: any
}

// Add Time of arrival
const timeOfArrival = document.createElement('p')
timeOfArrival.classList.add('bg-white', 'py-[5px]', 'px-[10PX]', 'ml-[10px]', 'text-sm', 'rounded-sm', 'hidden')

class SharedPositionClass extends React.Component<IProps, IState> {
    static contextType = DriverContext;
    context!: IDriverProvider;
    interval: NodeJS.Timer;
    lastPositionInterval: NodeJS.Timer;
    updateSocketPositionInterval: NodeJS.Timer;
    watchId;
    componentUpdatedOnce: boolean;
    wakelock: any;
    onATripZoom: number = 19;
    onATripTilt: number = 60;
    isAgainstRouteRef: boolean = undefined;
    isHeadingDeviationTooBig: boolean = false;
    onChangeZoomListener: any;
    fullscreenBtn = undefined;
    fullScreenBtnEvent: boolean = false;
    isFirstPositionError: boolean = true;

    constructor(args: any) {
        super(args);
        const url = window.location.href;
        const splitUrl = url.split("/");
        let companyNameFromUrl = "rezglo";

        if(splitUrl[3].includes("?")){
            const splitVariables = splitUrl[3].split("?");
            if(splitVariables[0] !== "app") {
                companyNameFromUrl = splitUrl[3];
            }
        } else {
            if(splitUrl[3] !== "app"){
                companyNameFromUrl = splitUrl[3];
            }
        }
        this.componentUpdatedOnce = false;    

        this.state = {
            location: undefined,
            pickupLocation: undefined,
            dropoffLocationName: "",
            locationName: "",
            lastTwoHeadings: [],
            countryName: "",
            dropOffTime: "",
            isShared: false,
            driverNotesVisible: false,
            map: undefined,
            tripStatus: StatusTripEnum.SHARED_POSITION,
            estimatedDurationPU: "0 min",
            estimatedDurationDO: "0 min",
            marker: undefined,
            loading: false,
            greetingSign: false,
            openModalCanceled: false,
            booking:undefined,
            companyLogo:undefined,
            companyName: companyNameFromUrl,
            PassengerGet: undefined,
            tripBelongsToDriver: true,
            isLocationBeingShared: false,
            durationTrip: '',
            legs: [],            
            directionsRenderer: undefined,
            borderDirectionsRenderer: undefined,
            accuracyCircle: undefined,
            cachedRouteDistance:undefined,
            isMapFullScreen: false,
            distanceCovered: 0,
            isTripCancelled: false
        };
        this.interval = null;
        this.wakelock = null;
        this.lastPositionInterval = null;
    }

    fetchLogo = async () => {
        try {

            const logo = await getcompanyLogo(this.state.companyName);
            this.setState({companyLogo: logo})

        } catch (error) {
            console.log(error);
        }
    }

    getPassengerById = async (id) => {
      try {
          if (id) {
              const {data:{ PassengerGet } } = await bookingModel.getPassengerById(id);
              this.setState({ PassengerGet });                          
          }         
      } catch (error) {
          throw new Error(error);
      }
    }

    enableWakelockIfPossible = async()=>{
      
      const anyNav: any = navigator

      if(anyNav){
        if('wakeLock' in anyNav){
          try {
            this.wakelock = await anyNav['wakeLock'].request('screen')
          } catch (error) {
            openNotificationWithIcon('error', error.name, error.message)
          }
        } else {
          openNotificationWithIcon('info', 'Info', 'Keeping screen active not supported by navigator')
        } 
      }
    }
    getCurrentTime = ()=> new Date().getTime();

    isPositionBeingShared = (timestamp: number)=>{
      const now = this.getCurrentTime();
      const timeDifference = now - timestamp;
      return timeDifference<=8500;
    };

    setTimestamp = (position: GeolocationPosition)=>{
      const timestamp = position.timestamp;
      localStorage.setItem('last-position-timestamp', JSON.stringify(timestamp));
    };

    errorTimestampPosition = (error: GeolocationPositionError)=>{
      
      if(error.message==='Timeout expired'){
        navigator.geolocation.clearWatch(this.watchId);
        setTimeout(this.watchLocation, 1000);
      } else {
        openNotificationWithIcon('error', 'Shared Position', error.message)
      }
      
    };

    checkInternetConnection = async()=>{
      try {
        await apiModel.pingServer();
        return true;
      } catch {         
        openNotificationWithIcon('error', 'Shared Position', 'No Internet connection');   
        return false;            
      }
    }

    checkIfPositionIsBeingObtained = async()=>{
            
      const lastPositionTimestamp = JSON.parse(localStorage.getItem('last-position-timestamp'));
      
      if(lastPositionTimestamp){
        const isLocationBeingShared = this.isPositionBeingShared(lastPositionTimestamp);
      
        if(!isLocationBeingShared){          
          navigator.geolocation.getCurrentPosition(this.setTimestamp, this.errorTimestampPosition, {...optionsPosition, timeout: 100});
          setTimeout(async() => {
            
              const updatedLastPositionTimestamp = JSON.parse(localStorage.getItem('last-position-timestamp'));
              const updatedIsLocationBeingShared = lastPositionTimestamp ? this.isPositionBeingShared(updatedLastPositionTimestamp) : false;
          
              if(updatedIsLocationBeingShared){
                try {
                  const isInternetConnection = await this.checkInternetConnection();
                  this.setState({isLocationBeingShared: isInternetConnection})  
                } catch (error) {
                  this.setState({isLocationBeingShared: false});
                  throw new Error(error)
                }            
              } else {
                this.setState({isLocationBeingShared: updatedIsLocationBeingShared})
              }
          }, 8000);
          
        } else{
          try {
            const isInternetConnection = await this.checkInternetConnection();
            this.setState({isLocationBeingShared: isInternetConnection})
          } catch (error) {
            this.setState({isLocationBeingShared: false});
            throw new Error(error)
          }          
        }
      } else {
        this.setState({isLocationBeingShared: false})
      };
    };

    socketCancelTripCallback = (isSameBooking: true, description: string)=>{
      const { booking } = this.context;
      openNotificationWithIcon(
        'warning',
        'Cancelled Trip',
        description
      );
      if(isSameBooking){
        navigator.geolocation.clearWatch(this.watchId);
        setTimeout(() => {
          if(booking.is_ride_now || booking.travel_status === TravelStatusTypeEnum.START_TO_PICKUP) {
            localStorage.setItem('driver-status', 'F');
          };
          window.location.href = window.location.pathname;
        }, 2000);
      }
    };

    socketRideNowTripCallback = (bookingId: string)=>{
      openInteractiveNotification({
        key: 'ride_now',
        type: 'warning',
        message: 'Ride Now Request',
        description: 'A Ride Now trip request has arrived! Tap to view trip details.',
        onClick: () => this.handleRideNowNotificationClick(bookingId),
        duration: 10,
      });
    };

    handleRideNowNotificationClick = (bookingId: string)=>{
      const { setState } = this.context;
      setState({ value: true, event: LOAD });
      notification.close('ride_now');
      window.location.href = `${window.location.pathname}?trip=${bookingId}`;
    };

    componentDidMount() {
        
      const { driverAppLogin, socket, driverData } = this.context;
      driverAppLogin( this.initMap );
      socket.initialize({
        id: driverData._id,
        cancelTravelCallback: this.socketCancelTripCallback,
        rideNowTripCallback: this.socketRideNowTripCallback
      });

      if(this.state.companyName !== "rezglo"){
          this.fetchLogo();
      };

      this.interval = setInterval(()=> {
        if(!this.fullscreenBtn){
          this.fullscreenBtn = document.querySelector('button.gm-fullscreen-control');        
          this.fullscreenBtn && navigator.geolocation.getCurrentPosition(this.successPosition, this.errorPosition, optionsPosition)
        } else {
          clearInterval(this.interval)
        }
      }, 500);

      this.enableWakelockIfPossible()

      this.lastPositionInterval = setInterval(this.checkIfPositionIsBeingObtained, 9000)
    }

    componentDidUpdate() {
        
        const { booking, driverData, history } = this.context        

        if ( booking && !this.componentUpdatedOnce ){

          let redirect = false;

          if(booking?.driver._id !== driverData._id){
            this.setState({tripBelongsToDriver: false});
            redirect = true;
          } else if ( this.isCancelledTrip ) {
            this.setState({isTripCancelled: true});
            redirect = true;
          };
          if(redirect){
            this.componentUpdatedOnce = true;
            const currentUrl = history.location.pathname;
            setTimeout(() => {
                history.push(currentUrl);
                history.go(0);
            }, 2000);
          }
        };
    }

  getColorByStatus = status => isEqual(status, 'ACTIVE') ? "text-[#31a697]" : "text-[#f39c12]";

  getNameByStatus = status => {
    if (isEqual(status, 'ACTIVE')) {
      return 'Available'
    }
    if (isEqual(status, 'IDLE')) {
      return 'Inactive'
    }
    return status
  }

  adjustMap = (mode: 'tilt' | 'reset-tilt' | 'rotate' | 'reset-rotate', amount: number = 0) => {
    const {
      state: { map },
    } = this
    switch (mode) {
      case 'tilt':
        return map.setTilt(map.getTilt()! + amount)
      case 'rotate':
        return map.setHeading(map.getHeading()! + amount)
      case 'reset-tilt':
        return map.setTilt(amount)
      case 'reset-rotate':
        return map.setHeading(amount)
      default:
        break
    }
  }
  
  initMap = (booking: any) => {
    
    const { isRezglo, socket } = this.context

    this.setState({ booking: booking })
    
    if (booking?.travel_status === TravelStatusTypeEnum.TRIP_CONFIRMED) {
      this.setState({ tripStatus: StatusTripEnum.ARRIVED })
    }
    if (booking?.travel_status === TravelStatusTypeEnum.START_TO_PICKUP) {
      this.setState({ tripStatus: StatusTripEnum.START_TRIP })
    }
    if (booking?.travel_status === TravelStatusTypeEnum.IN_PROGRESS) {
      this.setState({ tripStatus: StatusTripEnum.END_TRIP })
    }
    if (booking?.travel_status === TravelStatusTypeEnum.WAITING_FOR_PAYMENT) {
      this.setState({ tripStatus: StatusTripEnum.FINISH })
      openNotificationWithIcon('info', 'Complete Trip', 'Waiting for Payment.')
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((async(position: GeolocationPosition) => {
        const currentLocation: LocationType = {
            lat: parseFloat(String(position.coords.latitude)),
            lng: parseFloat(String(position.coords.longitude)),
        }
        this.setState({ location: currentLocation, isLocationBeingShared: true });

        const accuracy = position.coords.accuracy

        const mapElement = document.getElementById('rg-shared-map');
        
        if(mapElement){
          const routeColor = isRezglo ? '#E55130' : '#000000';
          const routeBorderColor = isRezglo ? '#A4292A' : 'gray'

          const zoom = booking?.travel_status === TravelStatusTypeEnum.TRIP_CONFIRMED
                || booking?.travel_status === TravelStatusTypeEnum.START_TO_PICKUP
                || booking?.travel_status === TravelStatusTypeEnum.IN_PROGRESS
                    ? this.onATripZoom : 6;

          const tilt = booking?.travel_status === TravelStatusTypeEnum.TRIP_CONFIRMED
                || booking?.travel_status === TravelStatusTypeEnum.START_TO_PICKUP
                || booking?.travel_status === TravelStatusTypeEnum.IN_PROGRESS
                    ? this.onATripTilt : 0;

          // @ts-ignore
          const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

          const directionsRenderer = new google.maps.DirectionsRenderer({
              polylineOptions: { strokeColor: routeColor, strokeWeight: 10 },
              suppressMarkers: true,
              preserveViewport: booking?.travel_status === TravelStatusTypeEnum.ASSIGNED ? false : true
          });

          const borderDirectionsRenderer = new google.maps.DirectionsRenderer({
            polylineOptions: { strokeColor: routeBorderColor, strokeWeight: 13 },
            suppressMarkers: true,
            preserveViewport: booking?.travel_status === TravelStatusTypeEnum.ASSIGNED ? false : true
          });
          
          const driverStatus = socket.getDriverStatus(this.state.tripStatus, booking);
          const circleColor = this.getCircleColor(driverStatus);

          const accuracyCircle = new google.maps.Circle({
            strokeColor: circleColor,
            strokeOpacity: 0.8,
            strokeWeight: 0.5,
            fillColor: circleColor,
            fillOpacity: 0.35,
            center: currentLocation,
            radius: accuracy
          })

          this.setState({directionsRenderer, borderDirectionsRenderer, accuracyCircle})

          const map = new Map(mapElement, {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            zoom,
            center: currentLocation,
            heading: 360,
            tilt,
            mapId: process.env.REZGLO_MAP_ID,
          });

          map.controls[google.maps.ControlPosition.LEFT_TOP].push(timeOfArrival)

          borderDirectionsRenderer.setMap(map)
          directionsRenderer.setMap(map);
          accuracyCircle.setMap(map);

          this.sendSocketData(
            driverStatus,
            SendDataEventEnum.driver_location_history_update,
            false
          );
          this.updateSocketPositionInterval = setInterval(()=>{
            const driverStatus = socket.getDriverStatus(this.state.tripStatus, booking);
            this.sendSocketData(
              driverStatus,
              SendDataEventEnum.driver_location_history_update,
              false
            );
          }, 25000);

          if (booking && booking?.travel_status !== TravelStatusTypeEnum.COMPLETE){
            const waypointsChecked = JSON.parse(localStorage.getItem('waypoints-checked'))
            for(let i=0; i<waypointsChecked; i++){
              booking.routesPoints.shift()
              this.setState({booking})
            };

            const { isTripAcceptedOrInProgress } = checkTravelStatus(booking)
            if(this.isTripInProgress){
              const distanceCovered = JSON.parse(localStorage.getItem('distance-covered')) || 0;
              this.setState({distanceCovered});
            } else {
              localStorage.removeItem('distance-covered');
            }

            calculateAndDisplayRoute(directionsRenderer, borderDirectionsRenderer, {
              booking,
              currentLocation,
              map,
              isMapInitializing: true,
              stateSetterCallback: ({ estimatedDurationPU, estimatedDurationDO, legs, directionsRenderer, borderDirectionsRenderer }) =>{
                this.setState({
                  estimatedDurationPU,
                  estimatedDurationDO,
                  legs,
                  directionsRenderer,
                  borderDirectionsRenderer
                });
                map && isTripAcceptedOrInProgress && this.setHeadingAtConfirmedTrip(legs[0]?.steps[0]?.path[0], legs[0]?.steps[0]?.path[1])
                setTimeout(() => {
                  this.onChangeZoomListener = google.maps.event.addListener(map, 'zoom_changed', ()=>setTimeout(() => {                    
                    setMapCenter({
                      cachedLocation: this.state.location, 
                      map, 
                      event: 'zoom',
                      booking: this.state.booking, 
                      isMapFullScreen: this.state.isMapFullScreen
                    })          
                  }, 500))  
                }, 2000);         
              },
            });
            
          } else {
            this.onChangeZoomListener = google.maps.event.addListener(map, 'zoom_changed', ()=>setTimeout(() => {              
              setMapCenter({
                cachedLocation: this.state.location, 
                map,
                booking: this.state.booking, 
                event: 'zoom', 
                isMapFullScreen: this.state.isMapFullScreen
              })   
            }, 500))          
          }

          getInfoLocation(currentLocation, (response) => {
              this.showInfoLocation(response);
              const marker = addMarker(currentLocation, map, response?.locationName);
              this.setState({ map, marker });
              setMapCenter({
                cachedLocation: this.state.location,
                booking: this.state.booking, 
                map, 
                event: 'init_map', 
                isMapFullScreen: this.state.isMapFullScreen
              });
              setTimeout(this.watchLocation, 200);
          });
        } else if(window.location.pathname.includes('/login')) {
          this.componentWillUnmount()
        }
      }), this.errorPosition, optionsPosition);
    } else {
      this.handleLocationError(false);
    }
  }

  componentWillUnmount() {        
    
    navigator.geolocation.clearWatch(this.watchId);
    google.maps.event && google.maps.event.removeListener(this.onChangeZoomListener);
    clearInterval(this.interval);
    clearInterval(this.lastPositionInterval);
    clearInterval(this.updateSocketPositionInterval);
    this.wakelock && this.wakelock.release();
    localStorage.removeItem('driver-status');
    
  }

  handleLocationError = (browserHasGeolocation: boolean) => {
    return browserHasGeolocation
      ? openNotificationWithIcon('error', 'Shared Position', 'The Geolocation service failed.')
      : openNotificationWithIcon('error', 'Shared Position', "Your browser doesn't support geolocation.")
  }

    watchLocation = () => {
        this.watchId = navigator.geolocation.watchPosition(this.successPosition, this.errorPosition, optionsPosition);
    }

    getIsThereATurnComing = (step)=>{      
      switch (step.maneuver) {
        case 'turn-right':
        case 'turn-left':
          return true;
        default:
          return false;
      }
    };

    getIsExpectedTurn = (obtainedLocation: LocationType)=>{
      const { legs } = this.state;
      
      if(legs?.length > 0){
        let isThereATurnComing: boolean;
        if(legs[0].steps[1]){
          isThereATurnComing = this.getIsThereATurnComing(legs[0].steps[1]);
        } else if (legs[1]){
          isThereATurnComing = this.getIsThereATurnComing(legs[1].steps[0]);
        };

        const firstPointOfTheRoute = legs[0].steps[0].path[0];
        
        const turningPoint = legs[0].steps[0].end_point;
        const distanceBetweenLocationAndTurningPoint = turningPoint ?
                                                            google.maps.geometry.spherical.computeDistanceBetween(obtainedLocation, turningPoint )
                                                            : 100;
        const distanceBetweenLocationAndRoute = google.maps.geometry.spherical.computeDistanceBetween(obtainedLocation, firstPointOfTheRoute);
        const isExpectedTurn = isThereATurnComing ? distanceBetweenLocationAndTurningPoint < 15 : false;
        const isTooFarFromOriginalRoute = distanceBetweenLocationAndRoute > 60;
        return {isExpectedTurn, isTooFarFromOriginalRoute}
        
      } else {
        return {isExpectedTurn: false, isTooFarFromOriginalRoute: false}
      }
    }

    setRoutesDistanceDifference = ()=>{
    
      const {legs, cachedRouteDistance} = this.state;
      
      let isItMoving: boolean;
      let isAgainstRoute: boolean;
      let totalRouteDistance = 0;
      if(legs){
        for(let i = 0; i<legs.length; i++){
          totalRouteDistance += legs[i].distance.value
        };
      }
      const routesDifference = totalRouteDistance - cachedRouteDistance;

      if(routesDifference <= 10 && routesDifference >=-10){
        isItMoving = false;
        isAgainstRoute = null        
      } else if(routesDifference<-6){
        this.setState({cachedRouteDistance: totalRouteDistance});
        isItMoving = true;
        isAgainstRoute = false; 
      } else {
        this.setState({cachedRouteDistance: totalRouteDistance});
        isItMoving = true;
        isAgainstRoute = true
      };

      if(isItMoving && this.isTripInProgress){
        const { distanceCovered } = this.state;
        const updatedDistanceCovered = distanceCovered + Math.abs(routesDifference)
        this.setState({ distanceCovered: updatedDistanceCovered })
        localStorage.setItem('distance-covered', JSON.stringify(updatedDistanceCovered));
      };

      return {isItMoving, isAgainstRoute}
  
    };
  
    setHeadingOnState = (currentLocationPoint: LocationType, nextLocationPoint:  LocationType, obtainedLocation?: LocationType)=>{
  
      let didHeadingChange: boolean;
  
      const { isAgainstRoute, isItMoving } = this.setRoutesDistanceDifference()
      const { lastTwoHeadings } = this.state;
      const [lastHeading, secondToLastHeading] = lastTwoHeadings;
      let isTooFarFromRoute = false;

      if(isItMoving){
  
        if(!this.isAgainstRouteRef || this.isAgainstRouteRef === isAgainstRoute ){
  
          const rawHeading = google.maps.geometry.spherical.computeHeading(nextLocationPoint, currentLocationPoint); 
          const actualHeading = isAgainstRoute ? rawHeading : rawHeading - 180
    
          let headingDeviation = actualHeading - lastHeading;
          headingDeviation = (headingDeviation >= 40 || headingDeviation <= -40 ) && secondToLastHeading ? actualHeading - secondToLastHeading : headingDeviation;

          secondToLastHeading && lastTwoHeadings.pop();
          lastTwoHeadings.unshift(actualHeading);

          if(headingDeviation >=40 || headingDeviation <=-40){

            const {isExpectedTurn, isTooFarFromOriginalRoute} = this.getIsExpectedTurn(obtainedLocation);
            
            isTooFarFromRoute = isTooFarFromOriginalRoute;
            didHeadingChange = isExpectedTurn;
            this.isHeadingDeviationTooBig = !isExpectedTurn;
            this.setState({lastTwoHeadings});
          
          } else{
            
            didHeadingChange = actualHeading === lastHeading ? 
                                                        this.isHeadingDeviationTooBig ? true : false
                                                        : true
            
            this.setState({lastTwoHeadings});
            this.isHeadingDeviationTooBig = false
          }
  
        } else{
          didHeadingChange = false
        };
  
        this.isAgainstRouteRef = isAgainstRoute;
      
      } else {
        didHeadingChange = false
      }
      return {didHeadingChange, isHeadingDeviationTooBig: this.isHeadingDeviationTooBig, isTooFarFromRoute};
  
    };
  
    setHeadingAtConfirmedTrip = (currentLocationPoint: LocationType, nextLocationPoint:  LocationType)=>{
      
      const { map, lastTwoHeadings } = this.state
  
        const heading = google.maps.geometry.spherical.computeHeading(nextLocationPoint, currentLocationPoint) - 180;       
        try {
          lastTwoHeadings.length === 2 && lastTwoHeadings.pop();
          lastTwoHeadings.unshift(heading);
          this.setState({lastTwoHeadings});
          map.setHeading(heading);          
          setMapCenter({
            cachedLocation: this.state.location, 
            booking: this.state.booking,
            map, 
            event: 'zoom', 
            isMapFullScreen: this.state.isMapFullScreen
          })   
        } catch (error) {
          if(error.message === `Cannot read property 'setHeading' of undefined`){
            this.setHeadingAtConfirmedTrip(currentLocationPoint, nextLocationPoint)
          } else {
            throw new Error(error)
          }
        }      
      
    }
    
    successPosition = (position) => {
      const { 
        state: { map, tripStatus, booking, directionsRenderer, borderDirectionsRenderer, legs, accuracyCircle }, 
        context: { socket, driverData } 
      } = this;
      const auxDrive: any = {
          bookingId: driverData.bookingId,
          userid: driverData._id,
          name: driverData.name,
          phone: driverData.phone,
          company_id: driverData.company_id,
          is_subscribed_company: driverData.is_subscribed_company,
          model_name: driverData?.driver_taxi_model,
          taxi_id: driverData?.driver_taxi_number,
          shift_id: driverData.shift_id,
          companyAdminId: driverData.companyAdminId,
      }
      if(booking && booking.passenger) {
        auxDrive.passenger_id = booking.passenger._id
      }
      const statusAux: DriverStatus = socket.getDriverStatus(tripStatus, booking);
      localStorage.setItem('driver-status', statusAux);
    
      this.setState({ loading: false });
      const location: LocationType = {
        lat: parseFloat(String(position?.coords?.latitude)),
        lng: parseFloat(String(position?.coords?.longitude))
      }
      const timestamp: number = position.timestamp;

      localStorage.setItem('last-position-timestamp', JSON.stringify(timestamp))

      const accuracy = position?.coords?.accuracy

      if(!this.fullScreenBtnEvent && this.fullscreenBtn){
        
        this.fullscreenBtn.addEventListener('click', ()=>{
            const isMapFullScreen = this.fullscreenBtn.ariaPressed === 'false' ? false : true  
            this.setState({isMapFullScreen});
            setTimeout(() => {
              const { location, booking, isMapFullScreen } = this.state            
              setMapCenter({
                cachedLocation: location,
                booking, 
                map, 
                event: 'zoom', 
                isMapFullScreen
              })   
            }, 50); 
        });
        this.fullScreenBtnEvent = true;
      }

      if(map){      

        if (!isEqual(this.state.location, location)){                
          
          setMapCenter({
            location,
            cachedLocation: this.state.location, 
            map, 
            event: 'success_position', 
            isMapFullScreen: this.state.isMapFullScreen
          })    
                
        };      
          
        accuracyCircle.setOptions({
          radius: accuracy,
          center: location,
          map
        });

        if(legs && (directionsRenderer?.preserveViewport === false || borderDirectionsRenderer.preserveViewport === false)){
          const mapZoom = map.getZoom();
          if(mapZoom > 9){
            directionsRenderer.preserveViewport = true;
            borderDirectionsRenderer.preserveViewport = true;
            this.setState({directionsRenderer, borderDirectionsRenderer})
          }
        }
        
        if(booking && booking.travel_status !== TravelStatusTypeEnum.COMPLETE && !isEqual(this.state.location, location) ){
                          
          calculateAndDisplayRoute(
            directionsRenderer, borderDirectionsRenderer,
            {
              booking, 
              currentLocation: location,            
              map,
              headingCallback: ({legs, map})=>{
                const {didHeadingChange, isHeadingDeviationTooBig, isTooFarFromRoute} = this.setHeadingOnState(legs[0]?.steps[0]?.path[0], legs[0]?.steps[0]?.path[1]);
                didHeadingChange && this.state.lastTwoHeadings.length > 0 && map.setHeading(this.state.lastTwoHeadings[0])
                return {isHeadingDeviationTooBig, isTooFarFromRoute}
              },            
              stateSetterCallback: ({directionsRenderer, estimatedDurationDO, estimatedDurationPU, legs, borderDirectionsRenderer})=>{
                this.setState({directionsRenderer, estimatedDurationDO, estimatedDurationPU, legs, borderDirectionsRenderer});
                this.displayDurationTrip(legs)
              }
            }
          )
        }
            
      } else {
        this.initMap(booking)
      }

      this.setState({ location });
      
      this.updateDriversMarker(auxDrive, statusAux, location);
      
      setIconMarker(this.state.marker, statusAux)
    }

    errorPosition = (error) => {
        const { context: { driverData } } = this;
        this.setState({isLocationBeingShared: false});        
        this.context.socket.updateDriverStatus(driverData?.userid);

        if(this.isFirstPositionError){
          openNotificationWithIcon('info', 'Shared Position', 'Please make sure location services are enabled.');
          this.isFirstPositionError = false;
        } else {
          openNotificationWithIcon('error', 'Shared Position', error.message);
        }

        if(error.message==='Timeout expired'){
            navigator.geolocation.clearWatch(this.watchId);
            setTimeout(this.watchLocation, 10000);
        }
    }

  sendSocketData = (
    driverStatus: DriverStatus, 
    event: SendDataEvent = SendDataEventEnum.driver_location_share,
    showNotification: boolean = true) => {
    const {
      state: { location, isShared, tripStatus, booking },
      context: { socket, setState, driverData: auxData },
    } = this
    const driverData = {
      ...auxData,
      userid: auxData?._id,
      model_name: auxData?.driver_taxi_model,
      taxi_id: auxData?.driver_taxi_number
    };
    if(booking && booking.passenger && event === SendDataEventEnum.driver_location_share) {
      driverData.passenger_id = booking.passenger._id
    };
    const sendData = () => {
      socket.sendData(event, { driverData, location, driverStatus }, () => {
        this.setState({ isShared: true })
        setState({ value: false, event: LOAD })
        !isShared &&
          tripStatus !== StatusTripEnum.CANCELED &&
          showNotification && openNotificationWithIcon('success', 'Shared Position', 'Your position has been shared.')
      })
    }
    if (location && !socket.isConnectedDriver) {
      socket.connect({
        id: driverData.userid,
        connectCallback: () => sendData(),
        cancelTravelCallback: this.socketCancelTripCallback,
        rideNowTripCallback: this.socketRideNowTripCallback,
      })
    }
    else sendData()
  }

  acceptTrip = async () => {
    const {
      context: { setState, tripId, booking, socket },
      state: {map, accuracyCircle, legs, directionsRenderer, borderDirectionsRenderer, tripStatus}
    } = this

    const driverStatus = socket.getDriverStatus(tripStatus, booking)
    let circleColor: string;
    if (tripId && booking.travel_status !== 'COMPLETE' && booking.travel_status !== 'CANCELLED_BY_DRIVER') {
      setState({ value: true, event: LOAD })
      try {
        await bookingModel.bookingAccept({
          tripId,
          driverReply: 'A',
          field: 'rejection',
          flag: 0,
        });
        this.setState({ tripStatus: StatusTripEnum.ARRIVED })
        booking.travel_status = TravelStatusTypeEnum.TRIP_CONFIRMED
        directionsRenderer.preserveViewport = true;
        borderDirectionsRenderer.preserveViewport = true;
        this.setHeadingAtConfirmedTrip(legs[0]?.steps[0]?.path[0], legs[0]?.steps[0]?.path[1])
        this.setState({booking, accuracyCircle, directionsRenderer, borderDirectionsRenderer})
        
        if(booking.is_ride_now){
          circleColor =  this.getCircleColor('B');
          this.sendSocketData('B');
          setIconMarker(this.state.marker, 'B');
          localStorage.setItem('driver-status', 'B')
        } else {
          circleColor =  this.getCircleColor(driverStatus);
          this.sendSocketData(driverStatus);
          setIconMarker(this.state.marker, driverStatus);
          localStorage.setItem('driver-status', driverStatus)
        }
        accuracyCircle.setOptions({fillColor: circleColor, strokeColor: circleColor})
      
      } catch {
        openNotificationWithIcon('error', 'Accept Trip', 'Internal Server Error.')
      } finally {
        setState({ value: false, event: LOAD });
        map && map.setZoom(this.onATripZoom);
        map && this.adjustMap('reset-tilt', this.onATripTilt);
      }
    } else if (
      booking.travel_status === 'REASSIGN' ||
      booking.travel_status === 'ASSIGNED' ||
      booking.travel_status === 'CANCELLED_BY_DRIVER'
    ) {
      this.setState({ tripStatus: StatusTripEnum.SHARED_POSITION, openModalCanceled: true })
    } else if (booking.travel_status === 'COMPLETE') {
      openNotificationWithIcon('info', 'Trip Info', 'Sorry, this trip is completed.')
    } else {
      openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
    }
  }

  arrivedTrip = async () => {
    const {
      context: { setState, tripId, travelTimeType, booking, setWaitingTime },
      state: {accuracyCircle },
    } = this
    if (travelTimeType === TravelTimeType.BOOK_LATER) {
      return openNotificationWithIcon(
        'info',
        'Arrived Trip',
        <>
          There are {<strong>{timeDiffTrip(moment(booking?.scheduled_time), moment())}</strong>} left for this trip to
          begin.
        </>,
      )
    }
    if (tripId) {
      setState({ value: true, event: LOAD })
      try {
        const circleColor = this.getCircleColor('B')
        await bookingModel.bookingArrived({ tripId })
        booking.travel_status = TravelStatusTypeEnum.START_TO_PICKUP;
        this.setState({ tripStatus: StatusTripEnum.START_TRIP })
        accuracyCircle.setOptions({fillColor: circleColor, strokeColor: circleColor})
        this.setState({booking, accuracyCircle})
        setWaitingTime(moment())
        setIconMarker(this.state.marker, 'B');
        this.sendSocketData('B');
        localStorage.setItem('driver-status', 'B')
      } catch {
        openNotificationWithIcon('error', 'Arrived Trip', 'Internal Server Error.')
      } finally {
        setState({ value: false, event: LOAD });
      }
    } else {
      openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
    }
  }

    startTrip = async () => {
        const { context: { setState, booking, tripId, setWaitingTime, waitingTime }, state: { location, accuracyCircle } } = this;

        if (tripId) {
            this.setState({ dropOffTime: moment() });
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingStart({
                    tripId,
                    status: "A",
                    latitude: String(location?.lat),
                    longitude: String(location?.lng),
                });
                const pickupLocation = {lat: location?.lat, lng: location.lng};
                booking.travel_status = TravelStatusTypeEnum.IN_PROGRESS
                accuracyCircle.setOptions({fillColor: '#ED1C24', strokeColor: '#ED1C24'})
                this.setState({ pickupLocation, booking, accuracyCircle, tripStatus: StatusTripEnum.END_TRIP });

                setWaitingTime(moment().diff(waitingTime, 'seconds'));
                setState({ value: false, event: LOAD });
                this.sendSocketData("B");
                setIconMarker(this.state.marker, "B");
                localStorage.setItem('driver-status', 'B')
            } catch {
                openNotificationWithIcon('error', 'Start Trip', "Internal Server Error.")
                setWaitingTime(moment().diff(waitingTime, 'seconds'));
                setState({ value: false, event: LOAD });
            } finally {
                setState({ value: false, event: LOAD });
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    stopReached = ()=>{
      
      const { 
        state: {booking},
        context: {setState}
      } = this;

      let waypointsChecked = JSON.parse(localStorage.getItem('waypoints-checked')) || 0 

      setState({event: LOAD, value: true})
      booking.routesPoints.shift()
      waypointsChecked++;
      localStorage.setItem('waypoints-checked', JSON.stringify(waypointsChecked))
      this.setState({booking})
      setTimeout(() => {
        setState({event: LOAD, value: false})
      }, 500);

    }

    endTrip = async () => {
        const { 
          context: { setState, tripId, booking, onOkConfirmationModal, waitingTime },
          state: { map, directionsRenderer, borderDirectionsRenderer, accuracyCircle },
        } = this;
        
        if (tripId) {
            setState({ value: true, event: LOAD });            
            try {
                await bookingModel.bookingComplete({
                    _id: tripId,
                    fleetFareType: booking?.fleetFareType,
                    waitingHours: waitingTime ? waitingTime * 0.000277778 : 0,
                    actualDistance: booking?.approx_distance
                });
                booking.travel_status = TravelStatusTypeEnum.COMPLETE
                accuracyCircle.setOptions({fillColor: '#2BB673', strokeColor: '#2BB673'})
                this.setState({booking, accuracyCircle, tripStatus: StatusTripEnum.FINISH})

                onOkConfirmationModal();
                directionsRenderer.setMap(null);
                borderDirectionsRenderer.setMap(null);
                setState({ value: false, event: LOAD })
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                localStorage.setItem('driver-status', 'F')
            } catch {
                openNotificationWithIcon('error', 'End Trip', "Internal Server Error.")
            } finally {
                map && this.adjustMap('reset-tilt')
                map && map.setZoom(6)
                setState({ value: false, event: LOAD })                
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    endTripPerMiles = async () => {
        const { 
          state: location, 
          context: { setState, tripId, booking, onOkConfirmationModal, setTipDetails, waitingTime, handleConfirmationModal }, 
          state: { map, directionsRenderer, borderDirectionsRenderer, distanceCovered }, 
        } = this;
        
        if (tripId) {
            setState({ value: true, event: LOAD });
            try {
                // TODO this will be implemented at a later date
                // if (navigator.geolocation) {
                // await navigator.geolocation.getCurrentPosition((async (position: GeolocationPosition) => {
                await getLocationName(location?.location, (response) => {
                    this.setState({
                        dropoffLocationName: response
                    });
                });
                const tripDetails = await restModel.endTripAction(booking, waitingTime ? waitingTime * 0.000277778 : 0, distanceCovered, this.state);
                booking.travel_status = TravelStatusTypeEnum.WAITING_FOR_PAYMENT;
                this.setState({booking, tripStatus: StatusTripEnum.FINISH});
                setTipDetails(tripDetails);
                directionsRenderer.setMap(null);
                borderDirectionsRenderer.setMap(null);
                setState({ value: false, event: LOAD })
                this.getPassengerById(this.state.booking?.passenger?._id)
                this.setState({ tripStatus: StatusTripEnum.FINISH });
                onOkConfirmationModal();
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                localStorage.setItem('driver-status', 'F')
                // }));
                // }
            } catch {
                openNotificationWithIcon('error', 'End Trip', "Internal Server Error.")
                handleConfirmationModal(false);
            } finally {
                map && this.adjustMap('reset-tilt');
                map && map.setZoom(6);
                setState({ value: false, event: LOAD })
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    endTripPayment = async (paymentMode) => {
        const { 
          context: { setState, tripId, booking, tripDetails, setOpenInvoiceDrawer, history }, 
          state: {accuracyCircle}
        } = this;
        const currentPath = history.location.pathname;
        const multiplier = booking?.distance_unit==="miles"? 0.000621371192 : 0.0001;
        setState({ value: true, event: LOAD });
        if (tripId) {
            try {
                const totalDistance = tripDetails.distance;
                const { data : { MakeFinalPayment } } = await bookingModel.bookingTripPayment({ 
                        trip_id: tripId,
                        payment_type: paymentMode,
                        fare: tripDetails?.total_fare,
                        actual_amount: tripDetails?.total_fare,
                        trip_fare: booking?.approx_fare,
                        promodiscount_amount: Number(tripDetails?.promodiscount_amount),
                        distance: totalDistance * multiplier || booking?.approx_distance * multiplier,
                        trip_type: booking?.trip_type,
                        base_fare: `${tripDetails?.base_fare}`,
                        passenger_promo_discount: "",
                        tax_amount: tripDetails?.tax_amount,
                        remarks: "",
                        nightfare_applicable: tripDetails?.nightfare_applicable,
                        nightfare: tripDetails?.nightfare,
                        eveningfare_applicable: tripDetails?.eveningfare_applicable,
                        eveningfare: tripDetails?.eveningfare,
                        waiting_time: tripDetails?.waiting_time,
                        waiting_cost: tripDetails?.waiting_cost,
                        company_tax: tripDetails?.company_tax,
                        passenger_discount: tripDetails?.passenger_discount,
                        minutes_traveled: tripDetails?.minutes_traveled,
                        minutes_fare: tripDetails?.minutes_fare,
                        fare_calculation_type: tripDetails?.fare_calculation_type,
                        model_fare_type: tripDetails?.model_fare_type,
                        creditcard_no:"",
                        expmonth:"",
                        expyear:"",
                });

                if(!isNullOrUndefined(MakeFinalPayment)){
                  const  { status, message } = MakeFinalPayment;

                  switch (status) {
                    case 200:
                        openNotificationWithIcon('success', 'Driver Web App', message || `The Trip was successfully paid.`);
                        setState({ value: false, event: LOAD })
                        setOpenInvoiceDrawer(false);
                        break;
                    default:
                        return openNotificationWithIcon('error', 'Server Error', message);
                    }
                };

                booking.travel_status = TravelStatusTypeEnum.COMPLETE;
                accuracyCircle.setOptions({fillColor: '#2BB673', strokeColor: '#2BB673'})
                this.setState({booking, accuracyCircle})
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                localStorage.setItem('driver-status', 'F')
            } catch(error) {
                showGraphqlErrorNotifications(error, "Payment Error");
                setState({ value: false, event: LOAD });
            } finally {
                setState({ value: false, event: LOAD });
                if(booking.travel_status === TravelStatusTypeEnum.COMPLETE){
                  setTimeout(() => {
                    history.push(currentPath);
                    history.go(0);
                  }, 2500);
                }
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

  getCurrentPosition() {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
      )
    })
  }

  displayDurationTrip(legs: any[]) {
      getDurationTrip(legs, response=>{ 
        this.setState({durationTrip: response})
      })

    if (this.state?.durationTrip) {
      timeOfArrival.classList.remove('hidden')
      timeOfArrival.innerHTML = `<strong>Estimated time of arrival</strong>: <span id='time-arrival'>${this.state?.durationTrip}</span>`
    }
  };

  updateDriversMarker(auxDrive, statusAux, location) {
    const {
      context: { socket },
    } = this

    this.state.marker?.setPosition(location);
    socket.sendData(SendDataEventEnum.driver_location_share, {driverData: auxDrive, location, driverStatus: statusAux});
  }

    cancelTrip = async () => {
        const { 
          context: { setState, tripId, refreshBooking, socket, booking, isRezglo }, 
          state: {accuracyCircle, tripStatus}
        } = this;
        
        if (tripId) {
            const driverStatus = socket.getDriverStatus(tripStatus, booking)
            setState({ value: true, event: LOAD });
            
            try {
                await bookingModel.bookingCancel({
                    tripId,
                    field: "rejection",
                });

                this.setState({accuracyCircle, tripStatus: StatusTripEnum.CANCELED });
                openNotificationWithIcon('success', 'Cancel Trip', "The Trip has been cancelled.")

                let circleColor: string;
                if(booking.is_ride_now || booking.travel_status === TravelStatusTypeEnum.START_TO_PICKUP){
                  circleColor = this.getCircleColor('F')
                  setIconMarker(this.state.marker, 'F');
                  localStorage.setItem('driver-status', 'F')
                } else {
                  circleColor = this.getCircleColor(driverStatus)
                  setIconMarker(this.state.marker, driverStatus);
                  localStorage.setItem('driver-status', driverStatus)
                }
                accuracyCircle.setOptions({fillColor: circleColor, strokeColor: circleColor});
                window.location.href = isRezglo ? '/app' : 'app';
                
              } catch {
                openNotificationWithIcon('error', 'Cancel Trip', "Internal Server Error.")
            } finally {
                refreshBooking();
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    rejectTrip = async () => {
       
        const { context: { setState, tripId, refreshBooking, history, socket } } = this;
        const currentPath = history.location.pathname;

        if (tripId) {
            setState({ value: true, event: LOAD });
            
            try {
            
                await bookingModel.bookingReject({
                    tripId,
                    reason: "",
                });
                this.setState({ tripStatus: StatusTripEnum.CANCELED });
                openNotificationWithIcon('success', 'Decline Trip', "The Trip has been declined.");
                
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                localStorage.setItem('driver-status', 'F')

                socket.disconnect()
                
                setTimeout(() => {
                    history.replace(currentPath);
                    history.go(0);
                }, 100);

                
            } catch {
                
                openNotificationWithIcon('error', 'Reject Trip', "Internal Server Error.")
                refreshBooking();

            } finally {
                setState({value: false, event: LOAD});


            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    getCircleColor = (driverStatus: 'B' | 'F')=>{
      switch (driverStatus) {
        case 'B':
          return '#ED1C24'
        case 'F':
          return '#2BB673'
      }
    }


  showInfoLocation = (response: GeoInfoType) => {
    if (response) {
      const { map, marker } = this.state
      const { locationName, stateName, countryName, location } = response
      this.setState({
        location,
        locationName: locationName,
        countryName: `${stateName}, ${countryName}`,
      })
      map && map.setZoom(this.onATripZoom)
      tryInfoPopup(map, marker, response)
    }
  }

  setTheme = () => {
    const { setTheme, isDark } = this.context
    setTheme && setTheme(!isDark ? 'dark' : 'light')
  }

  get configTripButton() {
    const {
      state: { tripStatus, estimatedDurationPU, estimatedDurationDO, dropOffTime, booking },
      context: { bt_primary, bt_secondary },
    } = this
    switch (tripStatus) {
      case StatusTripEnum.SHARED_POSITION:
      case StatusTripEnum.CANCELED:
        return {
          label: 'Accept Trip',
          timerLabel: 'Est. Pick-Up Time',
          timer: estimatedDurationPU,
          btnColor: bt_primary,
          action: this.acceptTrip,
        }
      case StatusTripEnum.ARRIVED:
        return {
          label: 'Arrived',
          timerLabel: 'Est. Pick-Up Time',
          btnColor: bt_primary,
          timer: estimatedDurationPU,
          action: this.arrivedTrip,
        }
      case StatusTripEnum.START_TRIP:
        return {
          label: 'Start Trip',
          timerLabel: undefined,
          timer: undefined,
          btnColor: bt_secondary,
          action: this.startTrip,
        }
      case StatusTripEnum.END_TRIP:
        if(booking.fleetFareType === 'PER_HOUR' && booking.routesPoints.length > 0 ){
          return {
            label: `Stop Reached (${booking.routesPoints.length} left)`,
            timerLabel: 'Travel Time',
            btnColor: bt_secondary,
            timer: <Chronometer />,
            action: this.stopReached
          }
        } else{
          return {
            label: 'End Trip',
            timerLabel: 'Travel Time',
            btnColor: bt_secondary,
            timer: <Chronometer />,
            action: () => this.context.handleConfirmationModal(true),
          }
      }
      case StatusTripEnum.FINISH:
        const dropOffTimeStorage = localStorage.getItem('dropOffTime') ?? dropOffTime
        if (!localStorage.getItem('dropOffTime')) localStorage.setItem('dropOffTime', dropOffTime)
        const estimatedDurationDOStorage = localStorage.getItem('estimatedDurationDO') ?? estimatedDurationDO
        if (!localStorage.getItem('estimatedDurationDO'))
          localStorage.setItem('estimatedDurationDO', estimatedDurationDO)

        return {
          label: 'Thank You!',
          timerLabel: 'Drop-Off Time',
          timer: this.isTripPerMiles ? dropOffTimeStorage : estimatedDurationDOStorage,
          action: () => {},
        }
    }
  }

  get allowCancel() {
    const { tripStatus } = this.state
    return tripStatus !== StatusTripEnum.FINISH && tripStatus !== StatusTripEnum.END_TRIP
  }

  get isTripPerMiles() {
    const { booking } = this.context
    return booking?.fleetFareType === 'PER_MILES'
  }

  get allowSharedPosition() {
    const { tripStatus } = this.state
    return tripStatus === StatusTripEnum.SHARED_POSITION || tripStatus === StatusTripEnum.CANCELED
  }

  get isStartTrip() {
    const { tripStatus } = this.state
    return tripStatus === StatusTripEnum.START_TRIP
  }

  get isTripInProgress()  {
    const { booking } = this.state;
    return booking?.travel_status === TravelStatusTypeEnum.IN_PROGRESS;
  };

  get isCancelOrDeclineTrip() {
    const { booking } = this.context
    return (
      booking?.travel_status === 'REASSIGN' ||
      booking?.travel_status === 'COMPLETE' ||
      booking?.travel_status === 'CANCELLED_BY_DRIVER'
    )
  }

  get isCancelledTrip() {
    const { booking } = this.context;
    return (
      booking?.travel_status === TravelStatusTypeEnum.TRIP_CANCELLED ||
      booking?.travel_status === TravelStatusTypeEnum.CANCELLED_BY_DRIVER ||
      booking?.travel_status === TravelStatusTypeEnum.CANCELLED_BY_DELETE_PASSENGER ||
      booking?.travel_status === TravelStatusTypeEnum.CANCELLED_BY_PASSENGER
    );
  };

  get isEndTrip() {
    const { tripStatus } = this.state
    return tripStatus === StatusTripEnum.END_TRIP
  }

  get isFinished() {
    const { tripStatus } = this.state
    return tripStatus === StatusTripEnum.FINISH
  }

  get showDriverNotes() {
    const {
      state: { tripStatus },
      context: { booking },
    } = this
    return (
      (booking?.driver?.notes_driver || booking?.passengerNotes) &&
      tripStatus !== StatusTripEnum.SHARED_POSITION &&
      tripStatus !== StatusTripEnum.FINISH
    )
  }

  get isOnTime() {
    const { booking } = this.context
    const actualDate = moment(new Date()).format('YYYY-MM-DD')
    const pickup = moment(booking?.pickup_time).format('YYYY-MM-DD')
    return moment(actualDate).isBefore(pickup) || moment(actualDate).isSame(pickup)
  }

    render() {
        const { loadingSession, 
                booking, 
                isDark, 
                state: { loading }, 
                bt_primary, 
                isRezglo, 
                travelTimeType, 
                history, 
                existTrip, 
                tripId, 
                driverData, 
                socket 
            } = this.context;
        const { tripStatus, openModalCanceled, greetingSign, driverNotesVisible, tripBelongsToDriver, isLocationBeingShared, isTripCancelled } = this.state;
        const isOutTimeTrip = travelTimeType === TravelTimeType.OUT_TIME;
        const isRightNowTrip = booking?.is_ride_now;
        const blockedSite = isOutTimeTrip && (booking?.travel_status === TravelStatusTypeEnum.ASSIGNED || booking?.travel_status === TravelStatusTypeEnum.COMPLETE);
        const isHome = tripId === undefined ? true : false;

        if(booking?.travel_status === 'REASSIGN' ) {
            
            const currentPath = history.location.pathname;
            history.replace(currentPath);
            history.go(0);
        };

        return (
            <Spining
                loading={loadingSession || loading}
                size={85}
            >
                <DriverInfo />
                <ConnectionStatus 
                  visible={blockedSite || !tripBelongsToDriver || isTripCancelled} 
                  existTrip={existTrip} 
                  tripBelongsToDriver={tripBelongsToDriver}
                  isTripCancelled={isTripCancelled}
                />
                <Row className={`p-5 ${blockedSite || !existTrip || !tripBelongsToDriver || isTripCancelled ? "driver-app-blocked-site" : ""}`} align="middle">
               
                  <> { !isHome &&
                    <Col span={12}>
                        <Header className="dark:text-white !text-2xl !font-bold">Ride ID: {booking?.number}</Header>
                    </Col>
                    } 

                    <Col span={!isHome ? 8 : 22} className="text-right">
                        {!isRezglo && (
                            <Icon
                                size="big"
                                className="cursor-pointer !text-black dark:!text-white"
                                onClick={this.setTheme}                                    
                                name={isDark ? 'sun' : 'moon'}
                                inverted={isDark}
                            />
                        )}                            
                    </Col>

                    <Col span={2}
                        className="text-right">
                        <FloatingButton isRezglo={isRezglo} socket={socket} />
                    </Col>
                  </>
                    {/* TODO */}
                    {/* {!isRezglo && !this.isFinished && <Col span={11}>
                        <Button
                            href={`tel:${booking?.passenger?.country_code} ${booking?.passenger?.phone}`}
                            icon
                            className={`w-full ${bt_primary} !text-white dark:!bg-dark-color dark:btn-box-not-shadow btn-box-shadow`}>
                            <Icon name="call" size="large" rotated="clockwise" />{"  "}
                            <span className="!text-xl">Make a Call</span>
                        </Button>
                    </Col>
                    } */}
                    <Col span={24} className="mt-4">
                        <Header as='h4'>
                            <div className="text-xl font-bold !border-b-2 dark:text-white border-black dark:border-white">
                                { !isHome ? 'Route Map' : 'Map'}
                            </div>
                        </Header>
                        <div className='h-[380px] overflow-hidden mb-5 rounded-lg dark:btn-box-not-shadow btn-box-shadow'>
                          <Row id='rg-shared-map' className='h-full mb-5 rounded-lg dark:btn-box-not-shadow btn-box-shadow'>
                              <img src={rezgloImg} className="inset-0 w-full h-full object-cover bg-gray-100 rounded-lg" />
                          </Row>
                        </div>
                    </Col>
                   
                    { isHome ?  
                        <Col span={24} className="flex">                                
                            {isRezglo ?
                                isDark 
                                    ? <img src={rezglologo} alt="logo" className="w-[126px] mb-5" /> 
                                    : <RezgloIconSvg />
                                : <img alt="logo" className="w-[126px] mb-5" src={this.state.companyLogo}/>
                            }
                        </Col>                     
                    : <>
                      {isRezglo && <Col span={11}>
                        {isDark ? <img src={rezglologo} alt="logo" className="w-[126px]" /> : <RezgloIconSvg />}
                    </Col>}
                     <Col span={isRezglo ? 13 : 24} className="text-right">
                        {this.isStartTrip &&
                            <TimerTrip timerLabel="Waiting Time" className="!mr-[10px]" count={<Chronometer />} />}
                        {!this.isStartTrip &&
                            <TimerTrip timerLabel={this.configTripButton.timerLabel} count={this.configTripButton.timer} />}
                    </Col>
                    <Col span={24} className="mt-5">
                        {this.allowSharedPosition ?
                            <Row gutter={[0, 16]}>
                                {this.isOnTime &&
                                    <Col span={24}>
                                        <Button
                                            onClick={this.configTripButton.action}
                                            size="massive"
                                            className={`dark:btn-box-not-shadow !p-[10px] !rounded-l-lg btn-box-shadow w-full h-24 !text-4xl ${this.configTripButton.btnColor} !text-white  dark:!bg-dark-color`}
                                            disabled={!isLocationBeingShared || !tripBelongsToDriver}
                                        >
                                            {this.configTripButton.label}
                                        </Button>
                                    </Col>
                                }
                                {isRightNowTrip && this.isOnTime && <Col span={24}>
                                    <Button
                                        onClick={this.rejectTrip}
                                        size="massive"
                                        disabled={this.isCancelOrDeclineTrip || !isLocationBeingShared}
                                        className={`dark:btn-box-not-shadow !p-[10px] ${this.isCancelOrDeclineTrip ? "!opacity-0" : ""} btn-box-shadow w-full h-24 !text-4xl !bg-red-700 !text-white !rounded-r-lg`}
                                    >
                                        Decline Trip
                                    </Button>
                                </Col>}
                            </Row>
                            :
                            <TripStop booking={booking} tripStatus={tripStatus} isTripPerMiles={this.isTripPerMiles}
                                onConfigTripButton={this.configTripButton.action} configTripButtonLabel={this.configTripButton.label}
                                configTripButtonBtnColor={this.configTripButton.btnColor} onCancelTrip={this.cancelTrip} isFinished={this.isFinished}
                                isLocationBeingShared={isLocationBeingShared}     
                            />}
                    </Col>
                    <TripDetails booking={booking} />
                    <PassengerInfo
                        booking={booking}
                        visible={greetingSign}
                        onCancel={() => this.setState({ greetingSign: false })}
                        tripStatus={tripStatus}
                        onOpen={() => this.setState({ greetingSign: true })}
                    />
                    <CancellationInfo
                        visible={openModalCanceled}
                        onCancel={() => this.setState({ openModalCanceled: false })}
                    />
                    {this.showDriverNotes &&
                        <Col span={24} className="mt-4">
                            <DriverNotes booking={booking} visible={driverNotesVisible}
                                onCancel={() => this.setState({ driverNotesVisible: false })} />
                            <Button
                                onClick={() => this.setState({ driverNotesVisible: true })}
                                size="massive"
                                className="w-full h-20 !text-3xl !bg-[#5F5F5F] !text-white !rounded-lg"
                            >
                                Driver Notes
                            </Button>
                        </Col>
                    }</>} 
                </Row>

                {isHome && (
                    <div 
                        className='rounded-t-xl p-1rem'
                        style={{
                            boxShadow: 'grey 0px -2px 7px 4px',
                        }}
                    >
                        <Row gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}>
                         
                            <Col xs={9} sm={10} lg={9} className="flex">
                                <div className={`text-[20px] sm:text-[30px] md:text-[30px] lg:text-[25px] mt-[1rem] font-semibold pl-[1rem] ${this.getColorByStatus(driverData.status)}`}>
                                    {this.getNameByStatus(driverData.status)}                                    
                                </div>  
                            </Col>
                            <Col xs={15} sm={14} lg={15} className="flex">
                                <div className="font-bold mt-4 dark:text-white border-black dark:border-white text-[20px] sm:text-[30px] md:text-[30px] lg:text-[25px] mb-4">                        
                                    {`${driverData.name} ${driverData.lastname }`}                    
                                </div> 
                            </Col>

              <Col xs={9} sm={10} lg={9} className="flex items-center justify-start !pl-[1.5rem]">
                <img
                  className="rounded-full avatar h-[80px] w-[80px] sm:h-[100px] sm:w-[100px] mb-5 sm:mb-0"
                  src={driverData.profile_picture || profileNoimage}
                  alt="Picture"
                />
              </Col>

                            <Col xs={15} sm={14} lg={15} className="!text-xl]">
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Vehicle Model:&nbsp;
                                    </Col>
                                    
                                    <Col >
                                       <span className='dark:text-white'>
                                         {driverData.driver_taxi_model}                            
                                       </span> 
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Plate Number:&nbsp;
                                    </Col>
                                    
                                    <Col>
                                        <span className='dark:text-white'>
                                            {driverData.driver_taxi_number}                            
                                        </span> 
                                       
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        License Number:&nbsp;
                                    </Col>
                                    
                                    <Col> 
                                        <span className='dark:text-white'>
                                            {driverData.driver_license_id}                          
                                        </span>                               
                                        
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Phone:&nbsp;
                                    </Col>
                                    
                                    <Col className='flex'>
                                        <img
                                          className="mr-[6px] w-[25px] h-[25px]"
                                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${driverData.phone_iso_country_code}.svg`}
                                        />
                                        <span className='dark:text-white'>                                           
                                          +{driverData.telephone_code} {driverData.phone}                         
                                        </span>  
                                      
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Email:&nbsp;
                                    </Col>
                                    
                                    <Col>
                                        <span className='dark:text-white'>
                                            {driverData.email}                       
                                        </span>  
                                       
                                    </Col>
                                </Row>                      
                            </Col>
                        </Row>
                    </div>
                )} 
                <EndTripConfirmation loading={loading} endTrip={booking?.fleetFareType === "PER_MILES" ? this.endTripPerMiles : this.endTrip} />
                <EndTripInvoice />
                <EndTripPaymentSelection 
                    payTrip={this.endTripPayment} 
                    loading={loading}
                    PassengerInfo={this.state.PassengerGet}                    
                />
            </Spining>
        );
    }
}

export default withQueryParams({ driverId: NumberParam }, SharedPositionClass);
